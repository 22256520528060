.sidebarAnimate {
  width: 100px;
  transition: width 0.1s;
  height: 200%;
  background: white;
  white-space: nowrap;
}

.sidebarAnimate:hover {
  width: 300px;
  padding-right: 10px;
  border-right: 1px solid #2283b5;
  z-index: 4 !important
}

.nav-pills .active {
  background-color: #2283b5 !important;
}

.card {
  border-radius: 10px;
}

.cardBackground {
  position: absolute;
  width: 100%;
  right: -24px;
  top: -11px;
}

.graphNotation {
  transform: rotate(270deg);
  position: absolute;
  top: 112px;
  left: -29px
}

.sidebarAnimate {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.sidebarAnimate::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.req-tab.nav-tabs .nav-link.active {
  color: #0d6efd;
}

.req-tab.nav-tabs .nav-link {
  color: #495057;
}