.modal-dialog {
  width: fit-content !important
}

.drag-file-area {
  border: 1px dashed #2d45ef;
  border-radius: 6px;
  margin: 10px 0 15px;
  padding: 30px 50px;
  width: 100%;
  text-align: center;
  background-color: #eff1ff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.upload-icon {
  font-size: 30px;
  color: #0518a2;
  position: relative;
}

.default-file-input {
  opacity: 0;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -10px;
  z-index: 1;
  cursor: pointer;
}

.share-request .card {
  display: block;
}